import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/ui/Pages/PageTitle'
import { AdminGetUsers } from '../../services/Api'
import { format, parseISO } from 'date-fns'
import { useApp } from '../../contexts/AppContext'
import PrimaryButton from '../../components/ui/Buttons/PrimaryButton'
import CreateUserModal from '../../components/domain/Users/CreateUserModal'

export default function Users() {
    const [users, setUsers] = useState([])
    const [showUserModal, setShowUserModal] = useState(false)
    const [editingUser, setEditingUser] = useState(null)

    const { setLoading } = useApp()

    useEffect(() => {
        loadUsers()
        // eslint-disable-next-line
    }, [])

    function loadUsers() {
        setLoading(true)
        AdminGetUsers()
            .then((response) => {
                setUsers(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    function handleCreateUser() {
        setEditingUser(null)
        setShowUserModal(true)
    }

    function showUser(user) {
        setEditingUser(user)
        setShowUserModal(true)
    }

    function onCreated() {
        loadUsers()
    }

    function onSaved() {
        setEditingUser(null)
        loadUsers()
    }

    return (
        <>
            {showUserModal && <CreateUserModal user={editingUser} onCreated={onCreated} onSaved={onSaved} onClose={() => setShowUserModal(false)} />}

            <PageTitle title="Usuários">
                <PrimaryButton onClick={handleCreateUser}>Novo usuário</PrimaryButton>
            </PageTitle>

            <div className="pt-8">
                {users.length > 0 ? (
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Matrícula
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Nome
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Criado em
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr
                                        className="border-b odd:bg-white even:bg-gray-50 hover:cursor-pointer hover:odd:bg-gray-100 hover:even:bg-gray-200"
                                        key={user.id}
                                        onClick={() => showUser(user)}
                                    >
                                        <td className="px-6 py-4">{user.registration_id}</td>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap">
                                            {user.name}
                                        </th>
                                        <td className="px-6 py-4">{user.email}</td>
                                        <td className="px-6 py-4">{format(parseISO(user.created_at), 'dd/MM/yyyy HH:mm')}</td>
                                        <td className="px-6 py-4">
                                            {user.active ? (
                                                <span className="text-xs bg-green-600 bg-opacity-80 rounded-full py-1 px-2 text-white">Ativo</span>
                                            ) : (
                                                <span className="text-xs bg-red-600 bg-opacity-80 rounded-full py-1 px-2 text-white">Inativo</span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Sem desenhos</div>
                )}
            </div>
        </>
    )
}
