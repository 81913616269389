import React from 'react'
import Modal from './Modal'

export default function Confirm({ text, onCancel, onConfirm }) {
    return (
        <Modal size="md" onCancel={onCancel} onSubmit={onConfirm} submitText="Sim">
            <p className="pt-6 pb-2">{text}</p>
        </Modal>
    )
}
