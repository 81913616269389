import React from 'react'

export default function ModalHeader({ title, onCancel, cancelDisabled }) {
    const cancelButtonClasses = ['text-gray-400', 'bg-transparent', 'rounded-lg', 'text-sm', 'p-1.5', 'ml-auto', 'inline-flex', 'items-center']

    const makeCancelButtonClass = () => {
        if (cancelDisabled) {
            return cancelButtonClasses.concat(['cursor-not-allowed']).join(' ')
        }
        return cancelButtonClasses.concat(['hover:bg-gray-200', 'hover:text-gray-900']).join(' ')
    }

    return (
        <div className="flex justify-between items-center p-5 rounded-t border-b">
            <h3 className="text-xl font-medium text-gray-900">{title}</h3>
            {onCancel && (
                <button type="button" onClick={onCancel} className={makeCancelButtonClass()} data-modal-toggle="medium-modal" disabled={cancelDisabled}>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            )}
        </div>
    )
}
