import React from 'react'

export default function FigureButton({ children, value, active, onClick }) {
    function getButtonStyle() {
        var buttonStyle = 'border rounded-lg px-6 py-3 text-sm flex items-center space-x-3 flex-shrink-0'
        if (active) {
            buttonStyle += ' bg-brand border-brand text-white hover:bg-opacity-90'
        } else {
            buttonStyle += ' text-gray-700 hover:bg-gray-100 border-gray-300'
        }
        return buttonStyle
    }

    function getBadgeStyle() {
        var buttonStyle = ' text-xs flex items-center justify-center rounded-lg px-2 py-1 font-medium'
        if (active) {
            buttonStyle += ' bg-brand-light text-white'
        } else {
            buttonStyle += ' bg-gray-200'
        }
        return buttonStyle
    }

    return (
        <button onClick={onClick} type="button" className={getButtonStyle()}>
            <span>{children}</span>
            <span className={getBadgeStyle()}>{value}</span>
        </button>
    )
}
