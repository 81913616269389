import React, { useState, useEffect } from 'react'
import { GetButtonSubDrawing } from '../../../services/Api'
import Modal from '../../ui/Modals/Modal'

export default function ViewSubDrawingButton({ drawing, button, onClose, onMapButtons }) {
    const [subDrawing, setSubDrawing] = useState()

    useEffect(() => {
        loadSubDrawing()
        // eslint-disable-next-line
    }, [])

    const loadSubDrawing = () => {
        GetButtonSubDrawing(drawing.id, button.id, button.target_id)
            .then((response) => {
                setSubDrawing(response.data.subdrawing)
            })
            .catch((error) => console.log(error))
            .finally(() => {})
    }

    return (
        <Modal title="Botão de sub-desenho" size="2xl" onCancel={onClose} onSubmit={onMapButtons} submitText="Mapear botões">
            <div>
                {subDrawing && (
                    <div className="flex flex-col space-y-6">
                        <div>
                            <img src={subDrawing.url} alt="Sub-desenho" />
                        </div>
                        <p className="font-medium text-gray-600">{subDrawing.name}</p>
                    </div>
                )}
            </div>
        </Modal>
    )
}
