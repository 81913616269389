import { useState, createContext, useContext } from 'react'

const AppContext = createContext()

function AppProvider({ children }) {
    const [loading, setLoading] = useState(false)

    return <AppContext.Provider value={{ loading, setLoading }}>{children}</AppContext.Provider>
}

function useApp() {
    return useContext(AppContext)
}

export { AppContext, AppProvider, useApp }
