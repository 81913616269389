export const getImageDimensions = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = (e) => {
            const image = new Image()
            image.src = e.target.result
            image.onload = (e) => {
                resolve({
                    height: e.target.height,
                    width: e.target.width,
                })
            }
        }
    })
}
