import React, { useState, useEffect } from 'react'
import Modal from '../../ui/Modals/Modal'
import Input from '../../ui/Forms/Input'
import { format } from 'date-fns'
import { AdminCreateUser, AdminSaveUser } from '../../../services/Api'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/AuthContext'
import ResetPasswordModal from './ResetPasswordModal'
import Button from '../../ui/Buttons/Button'

export default function CreateUserModal({ user, onSaved, onCreated, onClose }) {
    const [submitting, setSubmiting] = useState(false)

    const [registrationId, setRegistrationId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('basf' + format(new Date(), 'Y'))
    const [canAccessPortal, setCanAccessPortal] = useState(false)
    const [active, setActive] = useState(false)

    const [showResetPassword, setShowResetPassword] = useState(false)

    const { userHasRole } = useAuth()

    useEffect(() => {
        if (user) {
            setRegistrationId(user.registration_id)
            setName(user.name)
            setEmail(user.email)
            setActive(user.active)

            let userCanAccessPortal = userHasRole(user, 'backoffice')
            setCanAccessPortal(userCanAccessPortal)
        }
        // eslint-disable-next-line
    }, [user])

    function close() {
        if (onClose) {
            onClose()
        }
    }

    function handleSubmit() {
        setSubmiting(true)

        var data = {
            registration_id: registrationId,
            name,
            email,
            can_access_portal: canAccessPortal,
            active,
        }

        if (user) {
            AdminSaveUser(user.id, data)
                .then(() => {
                    toast.success('Usuário salvo com sucesso!')

                    if (onSaved) {
                        onSaved()
                    }
                    close()
                })
                .catch((error) => console.log(error))
                .finally(() => setSubmiting(false))
        } else {
            data.password = password

            AdminCreateUser(data)
                .then(() => {
                    toast.success('Usuário criado com sucesso!')

                    if (onCreated) {
                        onCreated()
                    }
                    close()
                })
                .catch((error) => console.log(error))
                .finally(() => setSubmiting(false))
        }
    }

    function canSubmit() {
        return !submitting
    }

    return (
        <>
            <Modal
                title="Usuário"
                onCancel={close}
                submitDisabled={!canSubmit()}
                onSubmit={handleSubmit}
                submitLoading={submitting}
                submitText="Salvar"
                submitLoadingText="Salvando ..."
            >
                <Input label="Matrícula" placeholder="Matrícula" disabled={submitting} value={registrationId} setValue={setRegistrationId} autoFocus />
                <Input label="Nome" placeholder="Nome" disabled={submitting} value={name} setValue={setName} />
                <Input label="Email" placeholder="Email" disabled={submitting} value={email} setValue={setEmail} />

                {!user && <Input label="Senha" placeholder="Senha" disabled={submitting} value={password} setValue={setPassword} />}

                <div className="pt-4">
                    <label htmlFor="checked-toggle" className="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" id="checked-toggle" className="sr-only peer" checked={canAccessPortal} onChange={(ev) => setCanAccessPortal(ev.target.checked)} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-brand"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900">Pode acessar o portal</span>
                    </label>
                </div>

                <div>
                    <label htmlFor="active-toggle" className="inline-flex relative items-center mb-4 cursor-pointer">
                        <input type="checkbox" id="active-toggle" className="sr-only peer" checked={active} onChange={(ev) => setActive(ev.target.checked)} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-brand"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900">Ativo</span>
                    </label>
                </div>

                {user && <Button onClick={() => setShowResetPassword(true)}>Resetar senha</Button>}
            </Modal>
            {showResetPassword && <ResetPasswordModal user={user} onClose={() => setShowResetPassword(false)} />}
        </>
    )
}
