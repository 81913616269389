import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppProvider } from './contexts/AppContext'
import AdminLayout from './layouts/AdminLayout'
import UnloggedLayout from './layouts/UnloggedLayout'
import Drawing from './pages/drawings/Drawing'
import Drawings from './pages/drawings/Drawings'
import Mapping from './components/domain/Mappings/Mapping'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ServiceOrders from './pages/serviceOrders/ServiceOrders'
import ServiceOrderItems from './pages/serviceOrders/ServiceOrderItems'
import { AdminRoute, AuthProvider, BasfAdminRoute, RequireAuth, RequireAuthAndNonExpiredPassword } from './contexts/AuthContext'
import Login from './pages/auth/Login'
import DrawingDetails from './components/domain/Drawings/DrawingDetails'
import DrawingParts from './components/domain/Parts/DrawingParts'
import DrawingSubDrawings from './components/domain/Drawings/DrawingSubDrawings'
import Home from './pages/Home'
import Users from './pages/users/Users'
import ChangePassword from './pages/auth/ChangePassword'

function App() {
    return (
        <AppProvider>
            <ToastContainer hideProgressBar={false} newestOnTop={true} />

            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<AdminLayout />}>
                            <Route element={<Home />}>
                                <Route
                                    index
                                    element={
                                        <RequireAuthAndNonExpiredPassword>
                                            <ServiceOrders />
                                        </RequireAuthAndNonExpiredPassword>
                                    }
                                />
                                <Route
                                    path="/service-orders"
                                    element={
                                        <RequireAuthAndNonExpiredPassword>
                                            <ServiceOrders />
                                        </RequireAuthAndNonExpiredPassword>
                                    }
                                />
                            </Route>
                            <Route
                                path="/service-orders/:serviceOrderId"
                                element={
                                    <RequireAuthAndNonExpiredPassword>
                                        <ServiceOrderItems />
                                    </RequireAuthAndNonExpiredPassword>
                                }
                            />

                            <Route element={<Home />}>
                                <Route
                                    path="/drawings"
                                    element={
                                        <RequireAuthAndNonExpiredPassword>
                                            <AdminRoute>
                                                <Drawings />
                                            </AdminRoute>
                                        </RequireAuthAndNonExpiredPassword>
                                    }
                                />
                            </Route>
                            <Route
                                path="/drawings/:drawingId"
                                element={
                                    <RequireAuthAndNonExpiredPassword>
                                        <AdminRoute>
                                            <Drawing />
                                        </AdminRoute>
                                    </RequireAuthAndNonExpiredPassword>
                                }
                            >
                                <Route index element={<DrawingDetails />} />
                                <Route path="parts" element={<DrawingParts />} />
                                <Route path="subdrawings" element={<DrawingSubDrawings />} />
                            </Route>

                            <Route
                                path="/drawings/:drawingId/mapping"
                                element={
                                    <RequireAuthAndNonExpiredPassword>
                                        <AdminRoute>
                                            <Mapping />
                                        </AdminRoute>
                                    </RequireAuthAndNonExpiredPassword>
                                }
                            />

                            <Route element={<Home />}>
                                <Route
                                    path="/users"
                                    element={
                                        <RequireAuthAndNonExpiredPassword>
                                            <BasfAdminRoute>
                                                <Users />
                                            </BasfAdminRoute>
                                        </RequireAuthAndNonExpiredPassword>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="/auth" element={<UnloggedLayout />}>
                            <Route index element={<Login />} />
                            <Route path="login" element={<Login />} />

                            <Route
                                path="password"
                                element={
                                    <RequireAuth>
                                        <ChangePassword />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </AppProvider>
    )
}

export default App
