import React from 'react'
import Button from './Button'

export default function PrimaryButton({ children, onClick, to, disabled, loading, block, type }) {
    return (
        <Button buttonStyle="primary" onClick={onClick} to={to} disabled={disabled} loading={loading} block={block} type={type}>
            {children}
        </Button>
    )
}
