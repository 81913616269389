import React from 'react'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

export default function Modal({
    children,
    title,
    onCancel,
    onSubmit,
    hideFooter,
    size,
    height,
    disabled,
    cancelDisabled,
    submitDisabled,
    submitText,
    submitLoading,
    submitLoadingText,
    loading,
}) {
    return (
        <div
            tabIndex="-1"
            className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-40 w-full md:inset-0 h-modal md:h-full bg-gray-500 bg-opacity-50 flex justify-center items-center pb-24"
        >
            <div className={`relative p-4 w-full h-full md:h-auto max-w-${size ?? '2xl'} overflow-y-hidden`} tabIndex="-1">
                <div className={`relative bg-white rounded-lg shadow flex flex-col ${height ?? ''}`}>
                    {/* Header */}
                    {title && <ModalHeader title={title} onCancel={onCancel} cancelDisabled={cancelDisabled} />}

                    {/* Body */}
                    <div className="p-6 space-y-6 overflow-y-auto flex-grow">{children}</div>

                    {loading && <div>Loading ...</div>}

                    {/* Footer */}
                    {!hideFooter && (
                        <ModalFooter
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                            disabled={disabled}
                            cancelDisabled={cancelDisabled}
                            submitDisabled={submitDisabled}
                            submitText={submitText}
                            submitLoading={submitLoading}
                            submitLoadingText={submitLoadingText}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
