import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import PrimaryButton from '../../components/ui/Buttons/PrimaryButton'
import { useApp } from '../../contexts/AppContext'
import { toast } from 'react-toastify'

export default function ChangePassword() {
    const { setLoading } = useApp()
    const { changePassword } = useAuth()

    const { user } = useAuth()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState(null)

    const navigate = useNavigate()

    function canSubmit() {
        return password.length > 0 && password === confirmPassword
    }

    function handleSubmit(event) {
        event.preventDefault()
        setError(null)
        setLoading(true)

        let data = {
            password: password,
            password_confirmation: confirmPassword,
        }

        changePassword(data, function (error) {
            if (!error) {
                toast.success('Senha alterada com sucesso!')
                navigate('/')
            } else {
                if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.password) {
                    var passwordErrors = []
                    error.response.data.errors.password.forEach((passwordError) => {
                        passwordErrors.push('<li class="mb-2">' + passwordError + '</li>')
                    })
                    setError('<ul class="list-disc pl-4">' + passwordErrors.join('') + '</ul>')
                } else if (error && error.response && error.response.data && error.response.data.message) {
                    setError(error.response.data.message)
                } else {
                    setError('Dados inválidos!')
                }
            }
        })
    }

    return (
        <div className="min-h-screen-nav flex justify-center items-center pb-40">
            <div className="bg-gray-100 border border-gray-200 py-6 px-8 rounded-lg max-w-sm w-full">
                <div className="font-bold text-2xl mb-8">
                    <p>Alterar senha</p>
                    {user && user.password_expired && <p className="font-normal text-sm mt-4">Sua senha está expirada. Para continuar você deve alterá-la aqui.</p>}
                </div>
                <form onSubmit={handleSubmit}>
                    {user && (
                        <div className="mb-6">
                            <div className="w-full rounded border border-gray-200 px-3 py-2">{user.registration_id}</div>
                        </div>
                    )}
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Senha"
                            className="w-full rounded border border-gray-200 px-3 py-2"
                            value={password}
                            onChange={(ev) => setPassword(ev.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Confirme a senha"
                            className="w-full rounded border border-gray-200 px-3 py-2"
                            value={confirmPassword}
                            onChange={(ev) => setConfirmPassword(ev.target.value)}
                        />
                    </div>

                    {error && (
                        <p className="text-sm text-red-600 font-medium pt-2">
                            <span dangerouslySetInnerHTML={{ __html: error }}></span>
                        </p>
                    )}

                    <div className="mt-10">
                        <PrimaryButton block type="submit" disabled={!canSubmit()}>
                            Enviar
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    )
}
