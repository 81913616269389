import React, { createContext, useState, useContext } from 'react'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Loading from '../components/ui/Loading'
import { ChangePassword, Login, Logout, Me } from '../services/Api'
import { useApp } from './AppContext'

const AuthContext = createContext()

function AuthProvider({ children }) {
    const token = window.sessionStorage.getItem('jwt')

    const [isLogged, setIsLogged] = useState(token != null)
    const [isPasswordExpired, setIsPasswordExpired] = useState(false)
    const [isAdminUser, setIsAdminUser] = useState(false)
    const [isBasfAdminUser, setIsBasfAdminUser] = useState(false)
    const [isBackofficeUser, setIsBackofficeUser] = useState(false)

    const [user, setUser] = useState(null)

    const { setLoading } = useApp()

    useEffect(() => {
        if (isLogged && !user) {
            Me()
                .then((response) => {
                    let me = response.data
                    setIsPasswordExpired(me.password_expired)
                    setIsAdminUser(userHasRole(me, 'admin'))
                    setIsBasfAdminUser(userHasRole(me, 'basf-admin'))
                    setIsBackofficeUser(userHasRole(me, 'backoffice'))
                    setUser(me)
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        }
        // eslint-disable-next-line
    }, [isLogged, user])

    function login(credentials, callback) {
        setLoading(true)
        Login(credentials)
            .then((response) => {
                window.sessionStorage.setItem('jwt', response.data.access_token)
                setIsLogged(true)
                callback()
            })
            .catch((error) => {
                callback(error)
            })
            .finally(() => setLoading(false))
    }

    function changePassword(data, callback) {
        setLoading(true)
        ChangePassword(data)
            .then(() => {
                setIsPasswordExpired(false)
                callback()
            })
            .catch((error) => {
                callback(error)
            })
            .finally(() => setLoading(false))
    }

    function logout() {
        setLoading(true)
        Logout().finally(() => {
            window.sessionStorage.removeItem('jwt')
            setUser(null)
            setIsLogged(false)
            setLoading(false)
            setIsPasswordExpired(false)
            setIsAdminUser(false)
        })
    }

    function userHasRole(user, role) {
        if (user && user.roles) {
            return user.roles.includes(role)
        }
        return false
    }

    const value = { isLogged, isPasswordExpired, isAdminUser, isBasfAdminUser, isBackofficeUser, user, userHasRole, login, changePassword, logout }

    return user || !isLogged ? <AuthContext.Provider value={value}>{children}</AuthContext.Provider> : <Loading />
}

function useAuth() {
    return useContext(AuthContext)
}

function RequireAuth({ children }) {
    const { isLogged } = useAuth()
    const location = useLocation()

    if (!isLogged) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    }

    return children
}

function RequireAuthAndNonExpiredPassword({ children }) {
    const { isLogged, isPasswordExpired } = useAuth()
    const location = useLocation()

    if (!isLogged) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    } else if (isPasswordExpired) {
        return <Navigate to="/auth/password" />
    }
    return children
}

function AdminRoute({ children }) {
    const { isLogged, isAdminUser } = useAuth()
    const location = useLocation()

    if (!isLogged) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    }

    if (!isAdminUser) {
        return <Navigate to="/" />
    }
    return children
}

function BasfAdminRoute({ children }) {
    const { isLogged, isAdminUser, isBasfAdminUser } = useAuth()
    const location = useLocation()

    if (!isLogged) {
        return <Navigate to="/auth/login" state={{ from: location }} replace />
    }

    if (!isAdminUser && !isBasfAdminUser) {
        return <Navigate to="/" />
    }
    return children
}

export { AuthContext, AuthProvider, useAuth, RequireAuth, RequireAuthAndNonExpiredPassword, AdminRoute, BasfAdminRoute }
