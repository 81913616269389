import { toast } from 'react-toastify'

export const toastError = (message, error = null) => {
    var errorMessage = message

    if (error && error.response && error.response.data && error.response.data.message) {
        errorMessage += ` "${error.response.data.message}"`
    }

    toast.error(errorMessage)
}
