import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import Page from '../../components/ui/Pages/Page'
import { useApp } from '../../contexts/AppContext'
import { GetDrawing } from '../../services/Api'

export default function Drawing() {
    const { drawingId } = useParams()
    const [drawing, setDrawing] = useState()
    const [selectedTab, setSelectedTab] = useState('drawing')

    const location = useLocation()
    const { setLoading } = useApp()

    useEffect(() => {
        function loadDrawing() {
            setLoading(true)
            GetDrawing(drawingId)
                .then((response) => {
                    setDrawing(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => setLoading(false))
        }

        loadDrawing()
        // eslint-disable-next-line
    }, [drawingId])

    useEffect(() => {
        if (location && location.state && location.state.tab) {
            setSelectedTab(location.state.tab)
        } else {
            setSelectedTab('drawing')
        }
    }, [location])

    const TabMenu = ({ children, to, state, selected }) => {
        const getStyleClasses = () => {
            // border-transparent hover:text-gray-600 hover:border-gray-300
            const defaultClasses = ['inline-block', 'p-4', 'rounded-t-lg', 'border-b-2']
            if (selected) {
                return defaultClasses.concat(['text-brand', 'border-brand']).join(' ')
            } else {
                return defaultClasses.concat(['border-transparent', 'hover:text-gray-600', 'hover:border-gray-300']).join(' ')
            }
        }

        return (
            <Link to={to} state={state} className={getStyleClasses()} aria-current="page">
                {children}
            </Link>
        )
    }

    const getBreadcrumb = () => {
        const rootBreadcrumb = [
            {
                title: 'Desenhos',
                to: '/drawings',
            },
        ]

        const treeBreadcrumb = []
        if (drawing.parentTree && drawing.parentTree.length > 0) {
            drawing.parentTree.forEach((drawing) => {
                treeBreadcrumb.push({
                    title: drawing.name,
                    to: `/drawings/${drawing.id}`,
                })
            })
        }

        return rootBreadcrumb.concat(treeBreadcrumb)
    }

    const getBackTo = () => {
        var backTo = '/drawings'

        if (drawing.parentTree && drawing.parentTree.length > 0) {
            const parentDrawings = []
            drawing.parentTree.forEach((drawing) => {
                parentDrawings.push(drawing.id)
            })

            backTo = backTo + '/' + parentDrawings.slice(-1)
        }

        return backTo
    }

    const handleDrawingUpdated = (updatedDrawing) => {
        setDrawing(updatedDrawing)
    }

    return (
        <>
            {drawing && (
                <>
                    <Page title={drawing.name} breadcrumb={getBreadcrumb()} backTo={getBackTo()}>
                        <div className="flex-col py-8">
                            <div className="mb-12 text-sm font-medium text-center text-gray-500 border-b border-gray-200 flex items-center justify-between">
                                <ul className="flex flex-wrap -mb-px">
                                    <li className="mr-2">
                                        <TabMenu to={`/drawings/${drawingId}`} selected={selectedTab === 'drawing'}>
                                            Desenho
                                        </TabMenu>
                                    </li>
                                    <li className="mr-2">
                                        <TabMenu to={`/drawings/${drawingId}/parts`} state={{ tab: 'parts' }} selected={selectedTab === 'parts'}>
                                            Peças
                                        </TabMenu>
                                    </li>
                                    <li className="mr-2">
                                        <TabMenu to={`/drawings/${drawingId}/subdrawings`} state={{ tab: 'subdrawings' }} selected={selectedTab === 'subdrawings'}>
                                            Sub-desenhos
                                        </TabMenu>
                                    </li>
                                </ul>
                            </div>

                            <Outlet context={{ drawing, updateDrawing: handleDrawingUpdated }} />
                        </div>
                    </Page>
                </>
            )}
        </>
    )
}
