import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function MainMenu({ selected }) {
    const { isAdminUser, isBasfAdminUser } = useAuth()
    const defaultClass = 'inline-block p-4 rounded-t-lg border-b-2 border-transparent'

    const getClassName = (menu) => {
        if (selected === menu) {
            return `${defaultClass} text-blue-600 border-blue-600 active`
        }
        return `${defaultClass} hover:text-gray-600 hover:border-gray-300`
    }

    return (
        <div className="mt-4 mb-8">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <ul className="flex flex-wrap -mb-px space-x-2">
                    <li>
                        <Link to="/service-orders" className={getClassName('service-orders')}>
                            Solicitações de Componente
                        </Link>
                    </li>

                    {isAdminUser && (
                        <li>
                            <Link to="/drawings" className={getClassName('drawings')}>
                                Desenhos
                            </Link>
                        </li>
                    )}

                    {(isAdminUser || isBasfAdminUser) && (
                        <li>
                            <Link to="/users" className={getClassName('users')}>
                                Usuários
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}
