import React, { useState, useEffect } from 'react'
import { SearchDrawingParts, UpdateDrawingButton } from '../../../services/Api'
import Input from '../../ui/Forms/Input'
import Spinner from '../../ui/Loading/Spinner'
import Modal from '../../ui/Modals/Modal'

export default function EditPartButton({ drawing, button, onClose }) {
    const [submitting, setSubmitting] = useState(false)
    const [searching, setSearching] = useState(false)
    const [parts, setParts] = useState([])
    const [keywords, setKeywords] = useState('')

    var searchTimeoutId = null

    useEffect(() => {
        search('')
        return () => {
            reset()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (keywords.length > 0) {
            search(keywords)
        } else {
            reset()
        }

        return () => {
            clearTimeout(searchTimeoutId)
        }
        // eslint-disable-next-line
    }, [keywords])

    const search = (keywords) => {
        searchTimeoutId = setTimeout(function () {
            setSearching(true)
            SearchDrawingParts(drawing.id, button.id, keywords)
                .then((response) => {
                    setParts(response.data)
                })
                .catch((error) => console.log(error))
                .finally(() => setSearching(false))
        }, 500)
    }

    const handleCancel = () => {
        onClose()
    }

    const handleSubmit = () => {
        onClose()
    }

    const canSubmit = () => {
        return true
    }

    const reset = () => {
        setSearching(false)
        setSubmitting(false)
        setKeywords('')
        setParts([])
    }

    const handleOnSelectPart = (part) => {
        const buttonData = {
            target_type: 'part',
            target_id: part.id,
        }

        button.target_id = part.id

        updateButton(buttonData)
    }

    const updateButton = (data) => {
        setSubmitting(true)
        UpdateDrawingButton(drawing.id, button.id, data)
            .then(() => {
                onClose()
            })
            .catch((error) => console.log(error))
    }

    return (
        <>
            <Modal
                title="Atribuir peça"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitDisabled={!canSubmit()}
                cancelDisabled={submitting}
                submitText="Salvar"
                submitLoading={submitting}
                submitLoadingText="Salvando..."
            >
                <div className="relative">
                    <Input placeholder="Encontre um peça pesquisando por posição, nome, etc" disabled={submitting} value={keywords} setValue={setKeywords} autoFocus rightPadding />
                    <div className="absolute right-4 top-1/2">{searching && <Spinner size={5} />}</div>
                </div>

                <div className="h-56 overflow-y-auto">
                    {parts.length > 0 && (
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <td className="text-sm font-semibold py-2">Peça</td>
                                    <td className="text-sm font-semibold py-2">Identificação</td>
                                    <td className="text-sm font-semibold py-2">Código BASF</td>
                                </tr>
                            </thead>
                            <tbody>
                                {parts.map((part) => {
                                    return (
                                        <tr
                                            key={part.id}
                                            onClick={() => handleOnSelectPart(part)}
                                            className="w-full border-b last:border-0 text-sm text-gray-600 hover:bg-gray-50 hover:cursor-pointer text-left active:bg-gray-100"
                                        >
                                            <td className="text-sm px-2 py-2">{part.name}</td>
                                            <td className="text-sm px-2 py-2">{part.identification}</td>
                                            <td className="text-sm px-2 py-2">{part.basf_code}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </Modal>
            {/* {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />} */}
        </>
    )
}
