import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { GetDrawingSubDrawings } from '../../../services/Api'

export default function DrawingSubDrawings() {
    const { drawing } = useOutletContext()
    const [subDrawings, setSubDrawings] = useState([])

    useEffect(() => {
        const loadSubDrawings = () => {
            GetDrawingSubDrawings(drawing.id)
                .then((response) => setSubDrawings(response.data))
                .catch((error) => console.log(error))
                .finally(() => {})
        }
        if (drawing) {
            loadSubDrawings()
        }
    }, [drawing])

    return (
        <div>
            {subDrawings.length > 0 ? (
                <div className="grid grid-cols-3 gap-8">
                    {subDrawings.map((subDrawing) => {
                        return (
                            <Link to={`/drawings/${subDrawing.id}`} key={subDrawing.id} className="border rounded-lg overflow-hidden hover:border-gray-400 hover:opacity-90">
                                <div className="h-64 bg-gray-50">
                                    <img src={subDrawing.url} className="h-64 object-cover w-full" alt="Sub-desenho" />
                                </div>
                                <div className="py-4 px-6 flex flex-col items-start">
                                    <p className="font-medium text-gray-600">{subDrawing.name}</p>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            ) : (
                <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Nenhum sub-desenho para este desenho</div>
            )}
        </div>
    )
}
