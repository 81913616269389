import React from 'react'
import CircularProgress from '../CircularProgress'
import Modal from './Modal'

export default function CircularProgressModal({ text, progress = 0, showPercent }) {
    return (
        <Modal size="sm" hideFooter>
            <div className="flex flex-col items-center space-y-3">
                <CircularProgress progress={progress} showPercent={showPercent} />
                {text && <p className="text-sm text-gray-800">{text}</p>}
            </div>
        </Modal>
    )
}
