import React from 'react'

const CIRCUMFERENCE = 30 * 2 * Math.PI

export default function CircularProgress({ progress = 0, showPercent }) {
    return (
        <div className="inline-flex items-center justify-center overflow-hidden rounded-full">
            <svg className="w-20 h-20 transform -rotate-90">
                <circle className="text-gray-300" strokeWidth="5" stroke="currentColor" fill="transparent" r="30" cx="40" cy="40" />
                <circle
                    className="text-brand"
                    strokeWidth="5"
                    strokeDasharray={CIRCUMFERENCE}
                    strokeDashoffset={CIRCUMFERENCE - (progress / 100) * CIRCUMFERENCE}
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="30"
                    cx="40"
                    cy="40"
                />
            </svg>
            {showPercent && progress > 0 && <span className="absolute text-brand text-sm">{progress}%</span>}
        </div>
    )
}
