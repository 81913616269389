import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetDrawingManufacturers, GetDrawingTypes, CreateDrawing as PostCreateDrawing, UpdateDrawingUrl, UploadDrawingImage } from '../../../services/Api'
import { getImageDimensions } from '../../../services/Utils'
import FileInput from '../../ui/Forms/FileInput'
import Input from '../../ui/Forms/Input'
import CircularProgressModal from '../../ui/Modals/CircularProgressModal'
import Modal from '../../ui/Modals/Modal'
import Select from '../../ui/Forms/Select'

export default function CreateDrawing({ buttonId, onClose, onSaved }) {
    const [submitting, setSubmitting] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const [isSubDrawing, setIsSubDrawing] = useState(false)

    const [name, setName] = useState('')
    const [drawingType, setDrawingType] = useState('')
    const [manufacturer, setManufacturer] = useState('')
    const [uploadFile, setUploadFile] = useState()

    const [loadingDrawingTypes, setLoadingDrawingTypes] = useState(false)
    const [drawingTypes, setDrawingTypes] = useState([])
    const [loadingManufacturers, setLoadingManufacturers] = useState(false)
    const [manufacturers, setManufacturers] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        setIsSubDrawing(buttonId != null)
    }, [buttonId])

    useEffect(() => {
        if (!isSubDrawing) {
            loadDrawingTypes()
            loadManufacturers()
        }

        // eslint-disable-next-line
    }, [isSubDrawing])

    useEffect(() => {
        if (drawingType === '' && drawingTypes.length > 0) {
            setDrawingType(drawingTypes[0].id)
        }

        // eslint-disable-next-line
    }, [drawingTypes])

    useEffect(() => {
        if (manufacturer === '' && manufacturers.length > 0) {
            setManufacturer(manufacturers[0].id)
        }

        // eslint-disable-next-line
    }, [manufacturers])

    function loadDrawingTypes() {
        setLoadingDrawingTypes(true)
        GetDrawingTypes().then((response) => {
            setDrawingTypes(response.data)
            setLoadingDrawingTypes(false)
        })
    }

    function loadManufacturers() {
        setLoadingManufacturers(true)
        GetDrawingManufacturers(drawingType).then((response) => {
            setManufacturers(response.data)
            setLoadingManufacturers(false)
        })
    }

    const handleSubmit = () => {
        setSubmitting(true)

        getImageDimensions(uploadFile).then((dimensions) => {
            let params = {
                name: name,
                fileName: uploadFile.name.replace(/\s/g, ''),
                width: dimensions.width,
                height: dimensions.height,
                is_subdrawing: isSubDrawing,
            }

            if (!isSubDrawing) {
                params.type = drawingType
                params.manufacturer_id = manufacturer
            }

            PostCreateDrawing(params)
                .then((response) => {
                    let drawingData = response.data

                    setUploading(true)

                    UploadDrawingImage(uploadFile, drawingData.presignedUrl, handleUploadProgress)
                        .then(() => {
                            setUploading(false)

                            UpdateDrawingUrl(drawingData.id, drawingData.url)
                                .then(() => {
                                    if (isSubDrawing) {
                                        onSaved(drawingData.id)
                                    } else {
                                        navigate(`/drawings/${drawingData.id}`)
                                    }
                                })
                                .catch((error) => console.log(error))
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        })
                        .catch((error) => console.log(error))
                        .finally(() => setUploading(false))
                })
                .catch((error) => {
                    setSubmitting(false)
                    console.log(error)
                })

            // setUploading(true)
        })
    }

    const handleUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
    }

    const handleCancel = () => {
        onClose()
    }

    const canSubmit = () => {
        let submitEnabled = name.length > 0 && uploadFile != null && !submitting
        if (submitEnabled && !isSubDrawing) {
            submitEnabled = drawingType && manufacturer && !loadingDrawingTypes && !loadingManufacturers
        }
        return submitEnabled
    }

    return (
        <>
            <Modal
                title={buttonId ? 'Novo sub-desenho' : 'Novo desenho'}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitDisabled={!canSubmit()}
                cancelDisabled={submitting}
                submitText="Salvar"
                submitLoading={submitting}
                submitLoadingText="Salvando..."
            >
                <Input label="Nome" placeholder="Nome do desenho" disabled={submitting} value={name} setValue={setName} autoFocus />

                {!isSubDrawing && (
                    <div className="flex items-center space-x-8">
                        <div className="w-1/2">
                            <Select className="mb-0" label="Tipo de desenho" value={drawingType} setValue={setDrawingType} loading={loadingDrawingTypes} disabled={submitting}>
                                {drawingTypes.map((drawingType) => {
                                    return <option value={drawingType.id}>{drawingType.name}</option>
                                })}
                            </Select>
                        </div>
                        <div className="w-1/2">
                            <Select className="mb-0" label="Fabricante" value={manufacturer} setValue={setManufacturer} loading={loadingManufacturers} disabled={submitting}>
                                {manufacturers.map((manufacturer) => {
                                    return <option value={manufacturer.id}>{manufacturer.name}</option>
                                })}
                            </Select>
                        </div>
                    </div>
                )}

                <FileInput file={uploadFile} setFile={setUploadFile} accept="image/jpeg" disabled={submitting} />
            </Modal>
            {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />}
        </>
    )
}
