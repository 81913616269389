import React from 'react'
import Modal from '../../ui/Modals/Modal'

export default function ButtonTypePicker({ onPick, onCancel }) {
    return (
        <>
            <Modal title="Escolha o tipo do botão" size="md" hideFooter onCancel={onCancel}>
                <div className="py-12 px-4 grid grid-cols-2 gap-8">
                    <button onClick={() => onPick('part')} type="button" className="rounded border flex flex-col items-center py-6 space-y-4 hover:bg-gray-50">
                        <svg className="w-12 h-12 text-brand" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z"></path>
                        </svg>
                        <p className="text-gray-600 font-medium text-sm">Peça</p>
                    </button>
                    <button onClick={() => onPick('subdrawing')} type="button" className="rounded border flex flex-col items-center py-6 space-y-4 hover:bg-gray-50">
                        <svg className="w-12 h-12 text-brand" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path>
                        </svg>
                        <p className="text-gray-600 font-medium text-sm">Sub-desenho</p>
                    </button>
                </div>
            </Modal>
        </>
    )
}
