import React, { useEffect, useState } from 'react'
import { UpdateDrawing, GetDrawingUrl, UploadDrawingImage, GetDrawingTypes, GetDrawingManufacturers } from '../../../services/Api'
import { getImageDimensions } from '../../../services/Utils'
import FileInput from '../../ui/Forms/FileInput'
import Input from '../../ui/Forms/Input'
import CircularProgressModal from '../../ui/Modals/CircularProgressModal'
import Modal from '../../ui/Modals/Modal'
import { toast } from 'react-toastify'
import { toastError } from '../../../utils/ToastHelper'
import Select from '../../ui/Forms/Select'

export default function EditDrawing({ drawing, onClose, onDrawingUpdated }) {
    const [submitting, setSubmitting] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const [name, setName] = useState('')
    const [drawingType, setDrawingType] = useState('')
    const [manufacturer, setManufacturer] = useState('')
    const [uploadFile, setUploadFile] = useState()
    const [uploadFileChanged, setUploadFileChanged] = useState(false)

    const [loadingDrawingTypes, setLoadingDrawingTypes] = useState(false)
    const [drawingTypes, setDrawingTypes] = useState([])
    const [loadingManufacturers, setLoadingManufacturers] = useState(false)
    const [manufacturers, setManufacturers] = useState([])

    useEffect(() => {
        setName(drawing.name)
        setDrawingType(drawing.drawing_type_id)
        setManufacturer(drawing.manufacturer_id)

        let file = new File([''], drawing.file_name, { type: 'image/jpg' })
        setUploadFile(file)

        loadDrawingTypes()
        loadManufacturers()

        // eslint-disable-next-line
    }, [drawing])

    function loadDrawingTypes() {
        setLoadingDrawingTypes(true)
        GetDrawingTypes().then((response) => {
            setDrawingTypes(response.data)
            setLoadingDrawingTypes(false)
        })
    }

    function loadManufacturers() {
        setLoadingManufacturers(true)
        GetDrawingManufacturers().then((response) => {
            setManufacturers(response.data)
            setLoadingManufacturers(false)
        })
    }

    const handleSubmit = () => {
        setSubmitting(true)

        if (uploadFileChanged) {
            getImageDimensions(uploadFile).then((dimensions) => {
                let params = {
                    name: name,
                    type: drawingType,
                    manufacturer_id: manufacturer,
                    fileName: uploadFile.name.replace(/\s/g, ''),
                    width: dimensions.width,
                    height: dimensions.height,
                }

                GetDrawingUrl(drawing.id, params.fileName)
                    .then((response) => {
                        let drawingData = response.data

                        setUploading(true)

                        UploadDrawingImage(uploadFile, drawingData.presignedUrl, handleUploadProgress)
                            .then(() => {
                                setUploading(false)

                                params.url = drawingData.url
                                updateDrawing(params)
                            })
                            .catch((error) => {
                                toastError('Erro ao fazer upload do desenho.', error)
                            })
                            .finally(() => setUploading(false))
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        console.log(error)
                    })

                // setUploading(true)
            })
        } else {
            updateDrawing({ name: name, type: drawingType, manufacturer_id: manufacturer })
        }
    }

    const updateDrawing = (params) => {
        UpdateDrawing(drawing.id, params)
            .then((response) => {
                toast.success('Desenho atualizado com sucesso!')
                onDrawingUpdated(response.data)
                onClose()
            })
            .catch((error) => {
                toastError('Erro ao atualizar o desenho.', error)
            })
            .finally(() => setSubmitting(false))
    }

    const handleUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
    }

    const handleCancel = () => {
        onClose()
    }

    const canSubmit = () => {
        let submitEnabled = name.length > 0 && uploadFile != null && !submitting
        if (submitEnabled && !drawing.is_subdrawing) {
            submitEnabled = drawingType && manufacturer && !loadingDrawingTypes && !loadingManufacturers
        }
        return submitEnabled
    }

    return (
        <>
            <Modal
                title="Editar desenho"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitDisabled={!canSubmit()}
                cancelDisabled={submitting}
                submitText="Salvar"
                submitLoading={submitting}
                submitLoadingText="Salvando..."
            >
                <Input label="Nome" placeholder="Nome do desenho" disabled={submitting} value={name} setValue={setName} autoFocus />

                {!drawing.is_subdrawing && (
                    <div className="flex items-center space-x-8">
                        <div className="w-1/2">
                            <Select className="mb-0" label="Tipo de desenho" value={drawingType} setValue={setDrawingType} loading={loadingDrawingTypes} disabled={submitting}>
                                {drawingTypes.map((drawingType, index) => {
                                    return (
                                        <option key={index} value={drawingType.id}>
                                            {drawingType.name}
                                        </option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="w-1/2">
                            <Select className="mb-0" label="Fabricante" value={manufacturer} setValue={setManufacturer} loading={loadingManufacturers} disabled={submitting}>
                                {manufacturers.map((manufacturer, index) => {
                                    return (
                                        <option key={index} value={manufacturer.id}>
                                            {manufacturer.name}
                                        </option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                )}
                <FileInput file={uploadFile} setFile={setUploadFile} accept="image/jpeg" disabled={submitting} onFileChanged={() => setUploadFileChanged(true)} />
            </Modal>
            {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />}
        </>
    )
}
