import React, { useState } from 'react'
import Modal from '../../ui/Modals/Modal'
import Input from '../../ui/Forms/Input'
import { format } from 'date-fns'
import { AdminResetUserPassword } from '../../../services/Api'
import { toast } from 'react-toastify'

export default function ResetPasswordModal({ user, onClose }) {
    const [submitting, setSubmiting] = useState(false)

    const [password, setPassword] = useState('basf' + format(new Date(), 'Y'))

    function close() {
        if (onClose) {
            onClose()
        }
    }

    function handleSubmit() {
        setSubmiting(true)
        AdminResetUserPassword(user.id, password)
            .then(() => {
                toast.success('Senha resetada com sucesso.')
                setSubmiting(false)
                close()
            })
            .catch((error) => {
                console.log(error)
                toast.error('Não foi possível resetar a senha. Tente novamente mais tarde.')
                setSubmiting(false)
            })
    }

    function isPasswordValid() {
        return password && password.length >= 6
    }

    function canSubmit() {
        return !submitting && isPasswordValid()
    }

    return (
        <Modal
            size="md"
            title="Usuário"
            onCancel={close}
            submitDisabled={!canSubmit()}
            onSubmit={handleSubmit}
            submitLoading={submitting}
            submitText="Resetar senha"
            submitLoadingText="Resetando a senha ..."
        >
            <Input label="Senha" placeholder="Senha" disabled={submitting} value={password} setValue={setPassword} />
        </Modal>
    )
}
