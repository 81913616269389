import React, { useEffect, useState } from 'react'
import { GetButtonPart } from '../../../services/Api'
import Modal from '../../ui/Modals/Modal'

export default function ViewPartButton({ drawing, button, onClose }) {
    const [part, setPart] = useState()

    useEffect(() => {
        loadPart()
        // eslint-disable-next-line
    }, [])

    const loadPart = () => {
        GetButtonPart(drawing.id, button.id, button.target_id)
            .then((response) => {
                console.log(response.data)
                setPart(response.data)
            })
            .catch((error) => console.log(error))
            .finally(() => {})
    }

    return (
        <Modal title="Botão de peça" size="2xl" onCancel={onClose}>
            <div>
                {part && (
                    <div>
                        <table className="w-full text-sm text-left text-gray-500">
                            <tbody>
                                <tr className="border-b bg-gray-100 text-sm">
                                    <td className="px-6 py-2">Posição</td>
                                    <td className="px-6 py-2 font-medium">{part.drawing_location}</td>
                                </tr>
                                <tr className="border-b bg-white text-sm">
                                    <td className="px-6 py-2">Denominação</td>
                                    <td className="px-6 py-2 font-medium">{part.name}</td>
                                </tr>
                                <tr className="border-b bg-gray-100 text-sm">
                                    <td className="px-6 py-2">Número de identificação</td>
                                    <td className="px-6 py-2 font-medium">{part.identification}</td>
                                </tr>
                                <tr className="border-b bg-white text-sm">
                                    <td className="px-6 py-2">Código BASF</td>
                                    <td className="px-6 py-2 font-medium">{part.basf_code}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </Modal>
    )
}
