import React from 'react'
import { Outlet } from 'react-router-dom'
import Loading from '../components/ui/Loading'
import Nav from '../components/ui/Nav'
import { useApp } from '../contexts/AppContext'

export default function AdminLayout() {
    const { loading } = useApp()
    return (
        <div>
            <Nav />
            <div className="relative pb-20">
                {loading && <Loading />}
                <Outlet />
            </div>
        </div>
    )
}
