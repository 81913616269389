import React from 'react'
import Breadcrumb from '../Breadcrumb'
import PageTitle from './PageTitle'

export default function Page({ children, title, backTo, headerRight, hideBreadcrumb, breadcrumb }) {
    return (
        <div>
            <div className="py-4 w-full max-w-7xl mx-auto">
                {!hideBreadcrumb && <Breadcrumb items={breadcrumb} />}
                {title && (
                    <PageTitle title={title} backTo={backTo}>
                        {headerRight}
                    </PageTitle>
                )}
                <div>{children}</div>
            </div>
        </div>
    )
}
