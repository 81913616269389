import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import PrimaryButton from '../../components/ui/Buttons/PrimaryButton'
import { useApp } from '../../contexts/AppContext'

export default function Login() {
    const { setLoading } = useApp()

    const { login } = useAuth()

    const [registrationId, setRegistrationId] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    const location = useLocation()
    const navigate = useNavigate()

    const from = location.state?.from?.pathname || '/'

    function handleSubmit(event) {
        event.preventDefault()
        setError(null)
        setLoading(true)

        login({ registrationId, password }, (error) => {
            if (!error) {
                navigate(from)
            } else {
                setError('Dados inválidos!')
            }
            setLoading(false)
        })
    }

    return (
        <div className="min-h-screen-nav flex justify-center items-center pb-40">
            <div className="bg-gray-100 border border-gray-200 py-6 px-8 rounded-lg max-w-sm w-full">
                <div className="font-bold text-2xl mb-4">Login</div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <input
                            type="text"
                            placeholder="Matrícula"
                            className="w-full rounded border border-gray-200 px-3 py-2"
                            value={registrationId}
                            onChange={(ev) => setRegistrationId(ev.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Senha"
                            className="w-full rounded border border-gray-200 px-3 py-2"
                            value={password}
                            onChange={(ev) => setPassword(ev.target.value)}
                        />
                    </div>

                    {error && <p className="text-sm text-red-600 font-medium">{error}</p>}

                    <div className="mt-10">
                        <PrimaryButton block type="submit">
                            Entrar
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    )
}
