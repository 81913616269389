import React, { useState } from 'react'
import EditDrawing from './EditDrawing'
import Button from '../../ui/Buttons/Button'
import { Link, useOutletContext } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function DrawingDetails() {
    const [editing, setEditing] = useState(false)
    const { drawing, updateDrawing } = useOutletContext()

    const DrawingData = ({ label, content, dark }) => {
        return (
            <div className={`flex justify-between px-4 py-3 ${dark ? 'bg-gray-200' : 'bg-gray-50'}`}>
                <div className="text-gray-700">{label}</div>
                <div className="">{content}</div>
            </div>
        )
    }

    const handleEdit = () => {
        setEditing(true)
    }

    // const handleDelete = () => {
    //     setEditing(true)
    // }

    return (
        <div className="flex">
            <div className="w-2/3 pr-12">
                {/* <div className="text-sm text-gray-600 mb-4">Imagem</div> */}

                {/* <img src={drawing.url} alt={drawing.name} className="w-full" /> */}
                {drawing.url ? (
                    <Link to={`/drawings/${drawing.id}/mapping`}>
                        <LazyLoadImage src={drawing.url} alt={drawing.name} className="w-full" />
                    </Link>
                ) : (
                    <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Nenhum desenho</div>
                )}
            </div>
            <div className="w-1/3">
                {/* <div className="text-sm text-gray-600 mb-4">Detalhes</div> */}

                <div className="flex items-center space-x-2 justify-end mb-6">
                    <Button onClick={handleEdit} thin>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            ></path>
                        </svg>
                        <span>Editar</span>
                    </Button>
                    <Button to={`/drawings/${drawing.id}/mapping`} thin>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            ></path>
                        </svg>
                        <span>Mapeamento</span>
                    </Button>

                    {/* <Button onClick={handleDelete} thin>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            ></path>
                        </svg>
                        <span>Excluir</span>
                    </Button> */}
                </div>

                <div className="flex flex-col text-xs">
                    <DrawingData label="Nome" content={drawing.name} dark />
                    {!drawing.is_subdrawing && (
                        <>
                            <DrawingData label="Tipo" content={drawing.drawing_type?.name} />
                            <DrawingData label="Fabricante" content={drawing.manufacturer?.name} dark />
                        </>
                    )}
                    <DrawingData label="Arquivo" content={drawing.file_name} />
                    <DrawingData label="Tamanho" content={`${drawing.width}px x ${drawing.height}px`} dark />
                </div>
            </div>

            {editing && <EditDrawing drawing={drawing} onClose={() => setEditing(false)} onDrawingUpdated={updateDrawing} />}
        </div>
    )
}
