import React, { useState, useEffect } from 'react'
import Modal from '../../ui/Modals/Modal'
import { GetServiceOrderReprovalReasons } from '../../../services/Api'

export default function ReprovalReasonPicker({ onPick, onClose }) {
    const [loading, setLoading] = useState(true)
    const [reasons, setReasons] = useState([])
    const [selectedReason, setSelectedReason] = useState(null)

    useEffect(() => {
        loadReasons()
    }, [])

    function loadReasons() {
        setLoading(true)
        GetServiceOrderReprovalReasons()
            .then((response) => {
                setReasons(response.data)
            })
            .catch((error) => {})
            .finally(() => setLoading(false))
    }

    function close() {
        setSelectedReason(null)
        if (onClose) {
            onClose()
        }
    }

    function handleSubmit() {
        if (selectedReason && onPick) {
            onPick(selectedReason)
        }
    }

    function canSubmit() {
        return selectedReason != null
    }

    return (
        <Modal
            title="Selecione o motivo da reprovação"
            height="h-[430px]"
            onCancel={close}
            submitDisabled={!canSubmit()}
            onSubmit={handleSubmit}
            submitText="Enviar"
            loading={loading}
        >
            <div className="overflow-y-hidden relative">
                <div className="flex flex-col space-y-4 overflow-y-auto">
                    {reasons.map((reason) => {
                        return (
                            <button
                                key={reason.id}
                                type="button"
                                onClick={() => setSelectedReason(reason)}
                                className={`px-4 py-3 text-sm rounded-xl text-left  ${
                                    selectedReason && selectedReason.id === reason.id ? 'bg-brand text-white hover:bg-brand-dark' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                                }`}
                            >
                                {reason.name}
                            </button>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}
