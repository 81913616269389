import React, { useEffect, useState } from 'react'
import Page from '../../components/ui/Pages/Page'
import PageBody from '../../components/ui/Pages/PageBody'
import PageTitle from '../../components/ui/Pages/PageTitle'
import { ApproveServiceOrder, CheckExportServiceOrder, ExportServiceOrder, GetServiceOrder, ReproveServiceOrder } from '../../services/Api'
import { useApp } from '../../contexts/AppContext'
import { useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import Button from '../../components/ui/Buttons/Button'
import { saveAs } from 'file-saver'
import PrimaryButton from '../../components/ui/Buttons/PrimaryButton'
import DangerButton from '../../components/ui/Buttons/DangerButton'
import { toast } from 'react-toastify'
import ServiceOrderStatus from '../../utils/ServiceOrderStatus'
import Confirm from '../../components/ui/Modals/Confirm'
import ReprovalReasonPicker from '../../components/domain/ServiceOrders/ReprovalReasonPicker'

export default function ServiceOrderItems() {
    const [order, setOrder] = useState()

    const { setLoading } = useApp()
    const { serviceOrderId } = useParams()

    const [confirmExported, setConfirmExported] = useState(false)
    const [showReprovalReasonPicker, setShowReprovalReasonPicker] = useState(false)

    useEffect(() => {
        setLoading(true)

        loadServiceOrder()
        // eslint-disable-next-line
    }, [serviceOrderId])

    function loadServiceOrder() {
        GetServiceOrder(serviceOrderId)
            .then((response) => {
                setOrder(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const getBreadcrumb = () => {
        const rootBreadcrumb = [
            {
                title: 'Solicitações de Componente',
                to: '/service-orders',
            },
        ]

        return rootBreadcrumb
    }

    const checkExportServiceOrder = () => {
        setLoading(true)
        CheckExportServiceOrder(serviceOrderId)
            .then((response) => {
                let exported = response.data.exported
                if (exported) {
                    setConfirmExported(true)
                } else {
                    exportServiceOrder()
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const exportServiceOrder = () => {
        setConfirmExported(false)
        setLoading(true)
        ExportServiceOrder(serviceOrderId)
            .then((response) => {
                loadServiceOrder()
                saveAs(response.data.url)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const approveServiceOrder = () => {
        setLoading(true)
        ApproveServiceOrder(order.id)
            .then((response) => {
                setOrder(response.data)
                toast.success('Solicitação de componente aprovada com sucesso!')
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const pickReprovalReason = () => {
        setShowReprovalReasonPicker(true)
    }

    const reprovalReasonPicked = (reason) => {
        setShowReprovalReasonPicker(false)
        reproveServiceOrder(reason.id)
    }

    const reproveServiceOrder = (reasonId) => {
        setLoading(true)
        ReproveServiceOrder(order.id, reasonId)
            .then((response) => {
                setOrder(response.data)
                toast.success('Solicitação de componente reprovada com sucesso!')
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const canExport = () => {
        return order.status.id === ServiceOrderStatus.WAITING_EXPORT || order.status.id === ServiceOrderStatus.APPROVED
    }

    return (
        <>
            {confirmExported && (
                <Confirm
                    text="Esta Solicitação de Componente já foi exportada. Deseja exportá-la novamente?"
                    onCancel={() => setConfirmExported(false)}
                    onConfirm={exportServiceOrder}
                />
            )}

            {showReprovalReasonPicker && <ReprovalReasonPicker onPick={reprovalReasonPicked} onClose={() => setShowReprovalReasonPicker(false)} />}

            <Page breadcrumb={getBreadcrumb()}>
                <PageTitle title="Solicitação de Componente" backTo="/service-orders">
                    {order && (
                        <div className="flex items-center space-x-3">
                            {order.status.id === ServiceOrderStatus.WAITING_ANALYSIS && (
                                <>
                                    <PrimaryButton onClick={approveServiceOrder}>Aprovar</PrimaryButton>
                                    <DangerButton onClick={pickReprovalReason}>Reprovar</DangerButton>
                                </>
                            )}
                            {canExport() && <Button onClick={checkExportServiceOrder}>Exportar</Button>}
                        </div>
                    )}
                </PageTitle>
                <PageBody>
                    {order && (
                        <div className="pb-10">
                            <div className="flex text-sm text-gray-800">
                                <div className="border py-4 px-6">
                                    <div className="text-xs mb-2 text-gray-600">Data</div>
                                    <div className="font-medium">{order.formattedDate}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0 flex-grow">
                                    <div className="text-xs mb-2 text-gray-600">Usuário</div>
                                    <div className="font-medium">{order.user_name}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0">
                                    <div className="text-xs mb-2 text-gray-600">OS BASF</div>
                                    <div className="font-medium">{order.os_basf}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0">
                                    <div className="text-xs mb-2 text-gray-600">Requer usinagem</div>
                                    <div className="font-medium">{order.requires_machining ? 'Sim' : 'Não'}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0">
                                    <div className="text-xs mb-2 text-gray-600">Tag</div>
                                    <div className="font-medium">{order.tag}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0">
                                    <div className="text-xs mb-2 text-gray-600">Operação</div>
                                    <div className="font-medium">{order.operation}</div>
                                </div>
                                <div className="border py-4 px-6 border-l-0">
                                    <div className="text-xs mb-2 text-gray-600">Status</div>
                                    <div className="font-medium">{order.status.name}</div>
                                </div>
                            </div>

                            {order.status.id === ServiceOrderStatus.REPROVED && order.status_reason != null && (
                                <div className="flex text-sm text-gray-800">
                                    <div className="border py-4 px-6 border-t-0 flex-grow">
                                        <div className="text-xs mb-2 text-gray-600">Motivo da reprovação</div>
                                        <p className="font-medium">{order.status_reason.name}</p>
                                    </div>
                                </div>
                            )}

                            {/* {order.comments && ( */}
                            <div className="flex text-sm text-gray-800">
                                <div className="border py-4 px-6 border-t-0 flex-grow">
                                    <div className="text-xs mb-2 text-gray-600">Observações</div>
                                    <p className="font-medium">{order.comments}</p>
                                </div>
                            </div>
                            {/* )} */}

                            <div className="mt-8 mb-6 text-lg font-semibold text-gray-600 border-t pt-3">Itens</div>
                            {order.items.length > 0 ? (
                                <table className="w-full text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Código BASF
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Peça
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Quantidade
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.items.map((item, index) => {
                                            return (
                                                <tr className="border-b odd:bg-white even:bg-gray-50" key={index}>
                                                    <td className="px-6 py-2">{item.part.basf_code}</td>
                                                    <td className="px-6 py-4">{item.part.name}</td>
                                                    <td className="px-6 py-4">{item.quantity}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Sem itens</div>
                            )}
                        </div>
                    )}
                </PageBody>
            </Page>
        </>
    )
}
