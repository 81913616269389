import React from 'react'
import Button from '../Buttons/Button'
import PrimaryButton from '../Buttons/PrimaryButton'

export default function ModalFooter({ onCancel, onSubmit, cancelText, submitText, disabled, cancelDisabled, submitDisabled, submitLoading, submitLoadingText }) {
    const getSubmitText = () => {
        var buttonTitle = submitText ?? 'Salvar'
        if (submitLoading && submitLoadingText) {
            buttonTitle = submitLoadingText
        }
        return buttonTitle
    }

    return (
        <div className="flex items-center justify-between p-6 rounded-b border-t border-gray-200 mt-2">
            {onCancel && (
                <Button onClick={onCancel} disabled={disabled || cancelDisabled}>
                    {cancelText ?? 'Cancelar'}
                </Button>
            )}
            {onSubmit && (
                <PrimaryButton onClick={onSubmit} disabled={disabled || submitDisabled} loading={submitLoading}>
                    {getSubmitText()}
                </PrimaryButton>
            )}
        </div>
    )
}
