import React, { useEffect, useRef } from 'react'
import { Rect, Transformer } from 'react-konva'

export default function RectangleButton({ id, x, y, width, height, fill, stroke, isSelected, onSelect, onDblClick, onMove, onResize }) {
    const rectRef = useRef()
    const trRef = useRef()

    useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([rectRef.current])
            trRef.current.getLayer().batchDraw()
        }
    }, [isSelected])

    const handleDragStart = (ev) => {}

    const handleDragEnd = (ev) => {
        let position = {
            x: ev.target.getX(),
            y: ev.target.getY(),
        }

        if (onMove) {
            onMove(position)
        }
    }

    const handleTransformEnd = (ev) => {
        const node = rectRef.current
        const scaleX = node.scaleX()
        const scaleY = node.scaleY()
        const width = node.width() * scaleX
        const height = node.height() * scaleY

        node.scaleX(1)
        node.scaleY(1)
        node.width(width)
        node.height(height)

        onResize({
            x: node.x(),
            y: node.y(),
            width: width,
            height: height,
        })
    }

    return (
        <>
            <Rect
                id={id}
                ref={rectRef}
                x={x}
                y={y}
                width={width}
                height={height}
                scaleX={1}
                scaleY={1}
                fill={fill}
                stroke={stroke}
                cornerRadius={3}
                strokeScaleEnabled={false}
                draggable
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onClick={onSelect}
                onDblClick={onDblClick}
                onTransformEnd={handleTransformEnd}
            />
            {isSelected && <Transformer ref={trRef} rotateEnabled={false} flipEnabled={false} anchorSize={8} ignoreStroke />}
            {/* {isSelected && (
                <RTransformer
                    anchorSize={4}
                    keepRatio={false}
                    anchorStrokeWidth={0}
                    ignoreStroke
                    cornerRadius={3}
                    anchorFill={'rgb(0, 161, 255)'}
                    borderEnabled={false}
                    rotateEnabled={false}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )} */}
        </>
    )
}
