import React from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../Buttons/BackButton'

export default function PageTitle({ title, backTo, children }) {
    const navigate = useNavigate()
    function handleClick() {
        if (backTo) {
            navigate(backTo)
        }
    }
    return (
        <div className="flex items-center justify-between">
            {backTo && (
                <div className="pr-3">
                    <BackButton onClick={handleClick} />
                </div>
            )}
            <h1 className="font-bold text-2xl flex-grow">{title}</h1>
            {children}
        </div>
    )
}
