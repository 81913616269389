import React from 'react'

export default function Input({ label, placeholder, disabled, value, setValue, autoFocus, rightPadding }) {
    const initialClasses = ['bg-gray-50', 'border', 'border-gray-300', 'text-sm', 'rounded-lg', 'focus:ring-gray-400', 'focus:border-brand', 'block', 'w-full', 'p-2.5']

    const makeClass = () => {
        const disabledClasses = getDisabledClasses()

        var extraClasses = []
        if (rightPadding) {
            extraClasses = initialClasses.concat(['pr-12'])
        }
        return initialClasses.concat(extraClasses, disabledClasses).join(' ')
    }

    const getDisabledClasses = () => {
        if (disabled) return ['text-gray-400', 'text-opacity-75', 'cursor-not-allowed']

        return ['text-gray-800']
    }

    const handleOnChange = (ev) => {
        setValue(ev.target.value)
    }

    return (
        <div className="mb-6">
            {label && <label className="block mb-2 text-sm font-medium text-gray-900 ">{label}</label>}

            <input type="text" className={makeClass()} placeholder={placeholder} value={value} onChange={handleOnChange} disabled={disabled} autoFocus={autoFocus} required />
        </div>
    )
}
