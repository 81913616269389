import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function Nav() {
    const { isLogged, user, logout } = useAuth()

    return (
        <nav className="bg-brand border-gray-200 px-2 h-16 flex items-center">
            <div className="w-full px-2 flex flex-wrap justify-between items-center mx-auto">
                <Link to="/" className="flex items-center">
                    <img src="/images/basf-logo-nav.png" className="mr-3 h-5" alt="Flowbite Logo" />
                </Link>

                {isLogged && user && (
                    <div className="flex flex-col items-center md:order-2 relative group py-2">
                        <div>
                            <button
                                type="button"
                                className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300"
                                id="user-menu-button"
                                aria-expanded="false"
                                data-dropdown-toggle="dropdown"
                            >
                                <span className="sr-only">Open user menu</span>
                                <img className="w-8 h-8 rounded-full" src={user.avatar_url} alt="" />
                            </button>

                            <div
                                className="hidden group-hover:flex absolute right-0 top-6 z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow flex-col"
                                id="dropdown"
                                data-popper-reference-hidden=""
                                data-popper-escaped=""
                                data-popper-placement="top"
                            >
                                <div className="py-3 px-4">
                                    <span className="block text-sm font-medium text-gray-800">{user.name}</span>
                                    <span className="block text-sm  text-gray-500 truncate">{user.email}</span>
                                </div>

                                <ul className="py-1" aria-labelledby="dropdown">
                                    <li>
                                        <button type="button" onClick={logout} className="text-left w-full py-2 px-4 text-sm text-red-500 hover:bg-gray-100">
                                            Sair
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button
                            data-collapse-toggle="mobile-menu-2"
                            type="button"
                            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                            aria-controls="mobile-menu-2"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </nav>
    )
}
