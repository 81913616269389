import React from 'react'
import RectangleButton from './RectangleButton'

export default function DrawingButton({ button, selectedButton, onSelect, onDblClick, onMove, onResize }) {
    const handleOnMove = (location) => {
        onMove(button, location)
    }

    const handleDoubleClick = () => {
        onDblClick(button)
    }

    const handleResize = (rect) => {
        onResize(button, rect)
    }

    return (
        <RectangleButton
            id={button.id}
            x={button.x}
            y={button.y}
            width={button.width}
            height={button.height}
            fill={button.target_type === 'part' ? 'rgba(0, 74, 150, 0.25)' : button.target_type === 'subdrawing' ? 'rgba(174, 23, 45, 0.25)' : 'rgba(0, 0, 0, 0.25)'}
            stroke={button.target_type === 'part' ? 'rgba(0, 74, 150, 0.85)' : button.target_type === 'subdrawing' ? 'rgba(174, 23, 45, 0.7)' : 'rgba(0, 0, 0, 0.6)'}
            isSelected={selectedButton && selectedButton.id === button.id}
            onSelect={() => {
                onSelect(button)
            }}
            onDblClick={handleDoubleClick}
            onMove={handleOnMove}
            onResize={handleResize}
        />
    )
}
