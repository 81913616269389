import React from 'react'
import { cn } from '../../../lib/utils'

export default function Select({ children, className, loading = false, label, placeholder, disabled, value, setValue, autoFocus, required }) {
    const initialClasses = ['bg-gray-50', 'border', 'border-gray-300', 'text-sm', 'rounded-lg', 'focus:ring-gray-400', 'focus:border-brand', 'block', 'w-full', 'p-2.5']

    const handleOnChange = (ev) => {
        setValue(ev.target.value)
    }

    const disabledOrLoading = () => {
        return disabled || loading
    }

    return (
        <div className={cn('mb-6', className)}>
            {label && <label className="block mb-2 text-sm font-medium text-gray-900 ">{label}</label>}

            {/* <input type="text" className={makeClass()} placeholder={placeholder} value={value} onChange={handleOnChange} disabled={disabled} autoFocus={autoFocus} required /> */}
            <select
                value={value || ''}
                placeholder={placeholder}
                className={cn(initialClasses, disabledOrLoading() ? 'opacity-40' : '')}
                disabled={disabled || loading}
                autoFocus={autoFocus}
                onChange={handleOnChange}
                required={required}
            >
                {loading ? <option>Carregando...</option> : children}
            </select>
        </div>
    )
}
