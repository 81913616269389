import React, { useRef } from 'react'

export default function FileInput({ file, setFile, accept, disabled, onFileChanged }) {
    const initialClasses = ['border', 'border-gray-300', 'text-sm', 'rounded-lg', 'w-full', 'p-2.5', 'flex', 'items-center', 'justify-between']

    const fileInputRef = useRef()

    const makeClass = () => {
        const disabledClasses = getDisabledClasses()
        return initialClasses.concat(disabledClasses).join(' ')
    }

    const getDisabledClasses = () => {
        if (disabled) return ['text-gray-400', 'text-opacity-75', 'bg-gray-50', 'cursor-not-allowed']

        return ['text-gray-800', 'bg-gray-50']
    }

    const handleFileChange = (ev) => {
        if (ev.target.files.length > 0) {
            const file = ev.target.files[0]
            setFile(file)

            if (onFileChanged) {
                onFileChanged()
            }
        }
    }

    const handleClearUploadFile = () => {
        setFile(null)
    }
    return (
        <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900">Arquivo da imagem</label>
            {file ? (
                <>
                    <div className={makeClass()}>
                        <div>{file.name}</div>
                        {!disabled && (
                            <button
                                type="button"
                                onClick={handleClearUploadFile}
                                className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm px-1.5 ml-auto inline-flex items-center"
                                data-modal-toggle="medium-modal"
                            >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div
                        onClick={() => fileInputRef.current.click()}
                        className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:ring-gray-400  block w-full p-2.5"
                    >
                        Escolha o arquivo
                    </div>
                    <input className="visually-hidden" type="file" accept={accept} name="file" ref={fileInputRef} onChange={handleFileChange} />
                </>
            )}
        </div>
    )
}
