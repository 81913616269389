import React, { useEffect, useRef, useState } from 'react'
import { Stage, Layer } from 'react-konva'
import { useResizeDetector } from 'react-resize-detector'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../contexts/AppContext'
import { CreateDrawingButton, DeleteDrawingButton, GetButtonSubDrawing, GetDrawing, GetDrawingButtons, UpdateDrawingButton } from '../../../services/Api'
import ButtonTypePicker from './ButtonTypePicker'
import DrawingBackgroundShape from './DrawingBackgroundShape'
import DrawingButton from './DrawingButton'
import EditPartButton from './EditPartButton'
import Spinner from '../../ui/Loading/Spinner'
import Confirm from '../../ui/Modals/Confirm'
import ReactTooltip from 'react-tooltip'
import ViewPartButton from './ViewPartButton'
import CreateDrawing from '../Drawings/CreateDrawing'
import ViewSubDrawingButton from './ViewSubDrawingButton'

export default function Mapping({ isSubDrawing }) {
    const canvasResizeDetector = useResizeDetector()
    const canvasContainerRef = useRef()
    const stageRef = useRef(null)
    const navigate = useNavigate()
    const { setLoading } = useApp()

    const [canvasContainerSize, setCanvasContainerSize] = useState({ width: 0, height: 0 })

    const { drawingId, buttonId, subDrawingId } = useParams()

    const [spinning, setSpinning] = useState(false)
    const [drawing, setDrawing] = useState()
    const [buttons, setButtons] = useState([])
    const [selectedButton, setSelectedButton] = useState(null)
    const [editing, setEditing] = useState(false)
    const [pickingButtonType, setPickingButtonType] = useState(false)
    const [confirmDeletion, setConfirmDeletion] = useState(false)
    const [showPartButton, setShowPartButton] = useState(false)
    const [showSubDrawingButton, setShowSubDrawingButton] = useState(false)

    useEffect(() => {
        if (canvasContainerRef) {
            setCanvasContainerSize({ width: canvasContainerRef.current.offsetWidth, height: canvasContainerRef.current.offsetHeight })
        }
    }, [canvasContainerRef])

    useEffect(() => {
        if (stageRef.current != null && canvasResizeDetector.width && canvasResizeDetector.height) {
            stageRef.current.width(canvasResizeDetector.width)
            stageRef.current.height(canvasResizeDetector.height)
        }
    }, [canvasResizeDetector.width, canvasResizeDetector.height])

    useEffect(() => {
        function loadDrawing() {
            setLoading(true)

            if (isSubDrawing) {
                GetButtonSubDrawing(drawingId, buttonId, subDrawingId)
                    .then((response) => {
                        setDrawing(response.data)

                        GetDrawingButtons(subDrawingId)
                            .then((response) => {
                                setButtons(response.data)
                            })
                            .catch((error) => console.log(error))
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoading(false)
                    })
                    .finally(() => {})
            } else {
                GetDrawing(drawingId)
                    .then((response) => {
                        setDrawing(response.data)

                        GetDrawingButtons(drawingId)
                            .then((response) => {
                                setButtons(response.data)
                            })
                            .catch((error) => console.log(error))
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setLoading(false)
                    })
                    .finally(() => {})
            }
        }

        loadDrawing()
        // eslint-disable-next-line
    }, [drawingId, buttonId, subDrawingId])

    const getDrawingId = () => {
        if (isSubDrawing) {
            return subDrawingId
        }
        return drawing.id
    }

    const checkStageDraggable = () => {
        return drawing.width > canvasContainerSize.width || drawing.height > canvasContainerSize.height
    }

    const handleStageDragging = (ev) => {
        stageRef.current.x(0)

        let position = stageRef.current.getAbsolutePosition()
        let widthBounds = canvasResizeDetector.width - drawing.width
        let heightBounds = canvasResizeDetector.height - drawing.height

        if (position.x > 0) {
            stageRef.current.x(0)
        } else if (position.x < widthBounds) {
            if (drawing.width > canvasContainerSize.width) {
                stageRef.current.x(widthBounds)
            } else {
                stageRef.current.x(0)
            }
        }

        if (position.y > 0) {
            stageRef.current.y(0)
        } else if (position.y < heightBounds) {
            if (drawing.height > canvasContainerSize.height) {
                stageRef.current.y(heightBounds)
            } else {
                stageRef.current.y(0)
            }
        }
    }

    const handleClose = () => {
        if (isSubDrawing) {
            navigate(`/drawings/${drawingId}/mapping`)
        } else {
            navigate(`/drawings/${drawingId}`)
        }
    }

    const handleAddButton = () => {
        setSelectedButton(null)
        setPickingButtonType(true)
    }

    const handleOnButtonTypePicked = (buttonType) => {
        setPickingButtonType(false)
        createButton(buttonType)
    }

    const handleDeleteButton = () => {
        setConfirmDeletion(true)
    }

    const createButton = (buttonType) => {
        setSelectedButton(null)

        let position = stageRef.current.getAbsolutePosition()

        let halfX = stageRef.current.getWidth() / 2
        let relativeCenterX = halfX - position.x - 100 / 2

        let halfY = stageRef.current.getHeight() / 2
        let relativeCenterXY = halfY - position.y - 40 / 2

        let button = {
            x: relativeCenterX,
            y: relativeCenterXY,
            width: 100,
            height: 40,
            target_type: buttonType,
        }

        setSpinning(true)
        CreateDrawingButton(getDrawingId(), button)
            .then((response) => {
                button.id = response.data
                setButtons(buttons.concat(button))
            })
            .finally(() => {
                setPickingButtonType(false)
                setSpinning(false)
            })
    }

    const checkDeselect = (e) => {
        const isRect = e.target.constructor.name === 'Rect'
        if (!isRect) {
            let parent = e.target.getParent()
            if (parent) {
                const clickedOnEmpty = parent.getParent() === e.target.getStage()
                if (clickedOnEmpty) {
                    setSelectedButton(null)
                }
            }
        }
    }

    const handleOnMoveSavedButton = (button, location) => {
        updateButton(button, location)
    }

    const updateButton = (button, data) => {
        setSpinning(true)

        const updatedButtons = buttons.slice().map((updatedButton) => {
            if (updatedButton.id === button.id) {
                updatedButton.x = data.x
                updatedButton.y = data.y

                if (data.width && data.height) {
                    updatedButton.width = data.width
                    updatedButton.height = data.height
                }
            }
            return updatedButton
        })
        setButtons(updatedButtons)

        UpdateDrawingButton(getDrawingId(), button.id, data).finally(() => setSpinning(false))
    }

    const moveButtonToTop = (button) => {
        const updatedButtons = buttons.slice()
        const updatedButton = updatedButtons.find((i) => i.id === button.id)
        const index = updatedButtons.indexOf(updatedButton)

        updatedButtons.splice(index, 1)

        updatedButtons.push(updatedButton)
        setButtons(updatedButtons)
    }

    const handleDoubleClick = (button) => {
        editButton(button)
    }

    const handleResize = (button, rect) => {
        updateButton(button, rect)
    }

    const editButton = (button) => {
        selectButton(button)

        if (button.target_type === 'part') {
            if (button.target_id) {
                setShowPartButton(true)
            } else {
                setEditing(true)
            }
        } else {
            if (button.target_id) {
                setShowSubDrawingButton(true)
            } else {
                setEditing(true)
            }
        }
    }

    const selectButton = (button) => {
        moveButtonToTop(button)
        setSelectedButton(button)
    }

    const deleteSelectedButton = () => {
        const updatedButtons = buttons.slice()
        const updatedButton = updatedButtons.find((i) => i.id === selectedButton.id)
        const index = updatedButtons.indexOf(updatedButton)

        updatedButtons.splice(index, 1)
        setButtons(updatedButtons)

        setSpinning(true)
        DeleteDrawingButton(getDrawingId(), selectedButton.id).finally(() => {
            setConfirmDeletion(false)
            setSpinning(false)
        })
    }

    const handleSubdrawingCreated = (subDrawingId) => {
        setLoading(true)

        const data = {
            target_id: subDrawingId,
        }

        UpdateDrawingButton(getDrawingId(), selectedButton.id, data)
            .then(() => {
                const updatedButtons = buttons.slice().map((updatedButton) => {
                    if (updatedButton.id === selectedButton.id) {
                        updatedButton.target_id = subDrawingId
                    }
                    return updatedButton
                })
                setButtons(updatedButtons)
            })
            .finally(() => {
                setEditing(false)
                setLoading(false)
            })
    }

    const handleSubDrawingMapping = () => {
        setShowSubDrawingButton(false)
        navigate(`/drawings/${selectedButton.target_id}/mapping`)
    }

    return (
        <div className="flex flex-col h-screen-nav">
            <div className="bg-silver-dark flex items-center px-4 text-sm text-white py-1">
                <button data-tip="Novo botão" type="button" className="border-silver pr-2 pl-1 py-2 flex items-center space-x-2 hover:text-blue-300" onClick={handleAddButton}>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
                    </svg>
                </button>

                <button
                    data-tip="Novo botão de Peça"
                    type="button"
                    className="border-0 border-silver pl-3 pr-2 py-2 flex items-center space-x-2 hover:text-blue-300"
                    onClick={() => createButton('part')}
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                        ></path>
                    </svg>
                </button>

                <button
                    data-tip="Novo botão de Sub-desenho"
                    type="button"
                    className="border-r border-silver pr-5 pl-3 py-2 flex items-center space-x-2 hover:text-blue-300"
                    onClick={() => createButton('subdrawing')}
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                    </svg>
                </button>
                {selectedButton && (
                    <>
                        <button
                            data-tip="Editar botão"
                            onClick={() => editButton(selectedButton)}
                            type="button"
                            className="border-r border-silver pr-4 pl-4 py-2 flex items-center space-x-2 hover:text-blue-300"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                            </svg>
                        </button>
                        <button
                            data-tip="Excluir botão"
                            onClick={handleDeleteButton}
                            type="button"
                            className="border-r border-silver pr-4 pl-4 py-2 flex items-center space-x-2 hover:text-blue-300"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                ></path>
                            </svg>
                        </button>
                    </>
                )}
                <div className="flex-grow"></div>

                {spinning && <Spinner size={5} />}

                <button className="text-white hover:text-blue-300 rounded-lg p-1 ml-4" onClick={handleClose}>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>

            <div className="flex-grow flex flex-col" ref={canvasContainerRef}>
                {canvasContainerRef && (
                    <div className="flex-grow bg-gray-800" ref={canvasResizeDetector.ref}>
                        {drawing && (
                            <Stage
                                ref={stageRef}
                                width={canvasContainerSize.width}
                                height={canvasContainerSize.height}
                                draggable={checkStageDraggable()}
                                onDragMove={handleStageDragging}
                                onMouseDown={checkDeselect}
                                onTouchStart={checkDeselect}
                            >
                                <Layer>
                                    <DrawingBackgroundShape url={drawing.url} width={drawing.width} height={drawing.height} />
                                    {buttons.map((button) => {
                                        return (
                                            <DrawingButton
                                                key={button.id}
                                                button={button}
                                                selectedButton={selectedButton}
                                                onSelect={() => {
                                                    selectButton(button)
                                                }}
                                                onMove={handleOnMoveSavedButton}
                                                onDblClick={handleDoubleClick}
                                                onResize={handleResize}
                                            />
                                        )
                                    })}
                                </Layer>
                            </Stage>
                        )}
                    </div>
                )}
            </div>
            <ReactTooltip delayShow={500} />

            {confirmDeletion && <Confirm text="Confirma a exclusão deste botão?" onCancel={() => setConfirmDeletion(false)} onConfirm={deleteSelectedButton} />}
            {pickingButtonType && <ButtonTypePicker onCancel={() => setPickingButtonType(false)} onPick={handleOnButtonTypePicked} />}
            {editing && selectedButton && (
                <>
                    {selectedButton.target_type === 'part' && <EditPartButton drawing={drawing} button={selectedButton} onClose={() => setEditing(false)} />}
                    {selectedButton.target_type === 'subdrawing' && (
                        <CreateDrawing buttonId={selectedButton.id} onClose={() => setEditing(false)} onSaved={handleSubdrawingCreated} />
                    )}
                </>
            )}
            {showPartButton && <ViewPartButton drawing={drawing} button={selectedButton} onClose={() => setShowPartButton(false)} />}
            {showSubDrawingButton && (
                <ViewSubDrawingButton drawing={drawing} button={selectedButton} onMapButtons={handleSubDrawingMapping} onClose={() => setShowSubDrawingButton(false)} />
            )}
        </div>
    )
}
