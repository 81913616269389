import React from 'react'

export default function BackButton({ onClick }) {
    return (
        <button onClick={onClick} className="w-8 h-8 bg-gray-100 flex items-center justify-center rounded-full hover:bg-gray-200">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
        </button>
    )
}
