import React from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumb({ items }) {
    const BreadcrumbItem = ({ children, to, home }) => {
        return (
            <li className="inline-flex items-center">
                {home ? <BreadcrumbHomeItem to={to}>{children}</BreadcrumbHomeItem> : <BreadcrumbLinkItem to={to}>{children}</BreadcrumbLinkItem>}
            </li>
        )
    }

    const BreadcrumbHomeItem = ({ children, to }) => {
        return (
            <>
                <BreadcrumbHomeIcon />
                <Link to={to} className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900">
                    {children}
                </Link>
            </>
        )
    }

    const BreadcrumbLinkItem = ({ children, to }) => {
        return (
            <div className="flex items-center">
                <BreadcrumbArrowIcon />
                <Link to={to} className="ml-1 text-sm text-gray-600 hover:text-gray-900 md:ml-1">
                    {children}
                </Link>
            </div>
        )
    }

    const BreadcrumbArrowIcon = () => {
        return (
            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                ></path>
            </svg>
        )
    }

    const BreadcrumbHomeIcon = () => {
        return (
            <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
        )
    }

    return (
        <nav className="flex mb-8" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-2">
                <BreadcrumbItem to="/" home>
                    Home
                </BreadcrumbItem>
                {items && items.length > 0 && (
                    <>
                        {items.map((item, index) => {
                            return (
                                <BreadcrumbItem to={item.to} key={index}>
                                    {item.title}
                                </BreadcrumbItem>
                            )
                        })}
                    </>
                )}
            </ol>
        </nav>
    )
}
