import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

export default function DrawingsGrid({ drawings }) {
    const navigate = useNavigate()
    function handleOnClick(drawing) {
        navigate(`/drawings/${drawing.id}`)
    }
    return drawings.length > 0 ? (
        <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                <tr>
                    <th scope="col" className="px-4 py-3">
                        Nome
                    </th>
                    <th scope="col" className="px-4 py-3">
                        Tipo
                    </th>
                    <th scope="col" className="px-4 py-3">
                        Fabricante
                    </th>
                    <th scope="col" className="px-4 py-3">
                        Peças
                    </th>
                    <th scope="col" className="px-4 py-3">
                        Sub-desenhos
                    </th>
                    <th scope="col" className="px-4 py-3">
                        Criado em
                    </th>
                </tr>
            </thead>
            <tbody>
                {drawings.map((drawing) => {
                    return (
                        <tr className="border-b hover:bg-gray-50 cursor-pointer" key={drawing.id} onClick={() => handleOnClick(drawing)}>
                            <th scope="row" className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap">
                                {drawing.name}
                            </th>
                            <td className="px-4">{drawing.drawing_type?.name}</td>
                            <td className="px-4">{drawing.manufacturer?.name}</td>
                            <td className="px-4">{drawing.partsCount}</td>
                            <td className="px-4">{drawing.subdrawingCount}</td>
                            <td className="px-4">{format(parseISO(drawing.created_at), 'dd/MM/yyyy HH:mm')}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    ) : (
        <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Sem desenhos</div>
    )
}
